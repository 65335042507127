// Keep vue provide/inject keys here
export const dataGridControlKey = Symbol('DataGridControl');
export const dataGridRefKey = Symbol('DataGridRef');
export const dataGridIdGroupByKey = Symbol('DataGridGroupBy');
export const dataGridEditorCellControlKey = Symbol('DataGridEditorCellControl');
export const dataColumnGroupKey = Symbol('ColumnGroup');

export const calendarGridControlKey = Symbol('CalendarGridControl');

export const tabControlKey = Symbol('TabControl');
export const sidePaneComponentKey = Symbol('SidePanel');
export const bootstrapTransitionPromiseKey = Symbol('BootstrapTransitionPromiseKey');
export const nestedStackKey = Symbol('NestedStack');
export const isInActiveComponentKey = Symbol('IsInActiveComponent');
export const filterStoreKey = Symbol('FilterStore');

export const pwaStoreKey = Symbol('PWA Store');
export const propertiesGridKey = Symbol('PropertiesGrid');
export const propertiesGridFilterKey = Symbol('PropertiesGridFilter');
export const propertiesGridFilterWithValues = Symbol('PropertiesGridWithValues');

export const mediaQueryProviderKey = Symbol('MediaQueryProvider');
export const containerSizeQueryProviderKey = Symbol('ContainerSizeQueryProvider');

